let navigate: (path: string) => void;

export const setNavigate = (navigateFunction: (path: string) => void) => {
  navigate = navigateFunction;
};

export const navigateTo = (path: string) => {
  if (navigate) {
    navigate(path);
  } else {
    console.error('Navigate function is not set');
  }
};