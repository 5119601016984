
import { ForgotPasswordFormData, LoginData } from '../store/interface';
//import axios from './_nodeConfigurations';
import { nodeServiceAxios } from './axiosInstances';

export const login = async(credentials: LoginData) =>{
  return await nodeServiceAxios.post('/users/login', credentials);
}

export const signup = async(details: { username: string; password: string }) =>{
  return await nodeServiceAxios.post('/users/signup', details);
}

export const logout =async()=>{
  debugger;
  return await nodeServiceAxios.post(`/users/logout`);
};

export const deleteAccount = async () =>{
  debugger;
  return await nodeServiceAxios.post('/users/deleteAccount');
}


export const getForgotOTP = async (request:ForgotPasswordFormData) =>{
  return await nodeServiceAxios.post('/users/forgot-password',request);
}

export const verifyForgotOTP = async  (request:ForgotPasswordFormData) =>{
  return await nodeServiceAxios.post('/users/verify-forgot-otp',request);
}

export const resetForgotPass = async (request:ForgotPasswordFormData) =>{
  return await nodeServiceAxios.post('/users/reset-password',request);
}