import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Loader from '../../components/loader/Loader';
import { ApiError, ForgotPasswordFormData } from '../../store/interface';
import { getForgotOTP, resetForgotPass, verifyForgotOTP } from '../../api/auth';
import Swal from 'sweetalert2';
import { AxiosError } from 'axios';
import { isDigits, isEmail, isPhoneNumber, validatePassword } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';

const initialFormData:ForgotPasswordFormData={
    email :"",
    username :"",
    phoneNumber :"",
    otp :"",
    newPassword:""
}

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();

    const [loader, setLoader] = useState<boolean>(false);
    const [formData, setformData] = useState<ForgotPasswordFormData>(initialFormData)
    const [epu, setEpu] = useState<string>("");
    const [epuError, setEpuError] = useState<string|null>(null);
    const [otp, setOtp] = useState<string>("");
    const [otpError, setOtpError] = useState<string|null>(null);
    const initialTime = 300; 
    const [otpResendTimeLeft, setOtpResendTimeLeft] = useState(initialTime);
    const [resendOtp, setResendOtp] = useState<boolean>(false);
    const [gotFirstOtp, setGotFirstOtp] = useState<boolean>(false);
    const [pass, setPass] = useState<string>("");
    const [confirmPass,setConfirmPass] = useState<string>("");
    const [passError, setPassError] = useState<string|null>(null);
    const [confirmPassError, setConfirmPassError] = useState<string|null>(null)

    const[otpVerified,setOtpVerified] = useState<boolean>(false);

    useEffect(() => {
        if (otpResendTimeLeft > 0) {
          const otpTimer = setTimeout(() => {
            setOtpResendTimeLeft(otpResendTimeLeft - 1);
          }, 1000);
          return () => clearTimeout(otpTimer);
        } else {
          setResendOtp(true);
        }
      }, [otpResendTimeLeft]);

    const getForgotPassOTP = async () => {
      try {
        setLoader(true);
        const response = await getForgotOTP(formData);
        setLoader(false)
         if(response.data.statusCode===200){
            setGotFirstOtp(true);
            setEpuError(null);
            setOtpResendTimeLeft(initialTime);
            setResendOtp(false);
                Swal.fire({
                    title: "Success",
                    text: response.data.message?response.data.message:"OTP has been sent!",
                    icon: "success",
                    position: "top",
                });
            }
            else{
                Swal.fire({
                    title: "Error",
                    text: response.data.message?response.data.message:"Something went wrong",
                    icon: "error",
                    position: "top",
                });
            }
      } catch (error) {
            setLoader(false);
            console.error("Error fetching checkout data:", error);
            let _errorMsg: string = "";
            let _errorHtml:string="";
            if (error instanceof AxiosError) {
            const _i:ApiError=error.response?.data;
            _errorMsg = _i.message;
            let a ="<ul>";
            _i.errors.map((item,index)=>{
                a=a+"<li>"+Object.values(item)+"</li>"
            });
            a=a+"</ul>";
            _errorHtml= a.length>10?a:_errorHtml;
    
            } else {
            _errorMsg = "Something went wrong! Try after sometime";
            }
            
            Swal.fire({
            title: "Error",
            text: _errorMsg,
            html:_errorHtml,
            icon: "error",
            position: "top",
            });
        }
    };
    
    const resendForgotOTP = async () => {
        try {
          setLoader(true);
          const response = await getForgotOTP(formData);
          setLoader(false)
           if(response.data.statusCode===200){
              setOtpResendTimeLeft(initialTime);
              setResendOtp(false);
                  Swal.fire({
                      title: "Success",
                      text: response.data.message?response.data.message:"OTP resent successfully!",
                      icon: "success",
                      position: "top",
                  });
              }
              else{
                  Swal.fire({
                      title: "Error",
                      text: response.data.message?response.data.message:"Something went wrong",
                      icon: "error",
                      position: "top",
                  });
              }
        } catch (error) {
              setLoader(false);
              console.error("Error fetching checkout data:", error);
              let _errorMsg: string = "";
              let _errorHtml:string="";
              if (error instanceof AxiosError) {
              const _i:ApiError=error.response?.data;
              _errorMsg = _i.message;
              let a ="<ul>";
              _i.errors.map((item,index)=>{
                  a=a+"<li>"+Object.values(item)+"</li>"
              });
              a=a+"</ul>";
              _errorHtml= a.length>10?a:_errorHtml;
      
              } else {
              _errorMsg = "Something went wrong! Try after sometime";
              }
              
              Swal.fire({
              title: "Error",
              text: _errorMsg,
              html:_errorHtml,
              icon: "error",
              position: "top",
              });
          }
      }; 

    const verifyForgotPassOTP = async () => {
        debugger;
        try {
          setLoader(true);
          const response = await verifyForgotOTP(formData);
          setLoader(false)
           if(response.data.statusCode===200){
                setOtpVerified(true);
                  Swal.fire({
                      title: "Success",
                      text: response.data.message?response.data.message:"OTP has been verified!",
                      icon: "success",
                      position: "top",
                  });
              }
              else{
                  Swal.fire({
                      title: "Error",
                      text: response.data.message?response.data.message:"Something went wrong",
                      icon: "error",
                      position: "top",
                  });
              }
        } catch (error) {
            setLoader(false);
            console.error("Error fetching checkout data:", error);
            let _errorMsg: string = "";
            let _errorHtml:string="";
            if (error instanceof AxiosError) {
            const _i:ApiError=error.response?.data;
            _errorMsg = _i.message;
            let a ="<ul>";
            _i.errors.map((item,index)=>{
                a=a+"<li>"+Object.values(item)+"</li>"
            });
            a=a+"</ul>";
            _errorHtml= a.length>10?a:_errorHtml;
    
            } else {
            _errorMsg = "Something went wrong! Try after sometime";
            }
            
            Swal.fire({
            title: "Error",
            text: _errorMsg,
            html:_errorHtml,
            icon: "error",
            position: "top",
            });
        }
    };
    
    const resetForgotPassword = async () => {
        debugger;
        try {
          setLoader(true);
          const response = await resetForgotPass(formData);
          setLoader(false)
           if(response.data.statusCode===200){
                Swal.fire({
                    title: "Success",
                    text: response.data.message?response.data.message:"Your password is updated!",
                    icon: "success",
                    confirmButtonColor: "#0d6efd",
                    confirmButtonText:"OK"
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/login");
                    }
                });
              }
              else{
                  Swal.fire({
                      title: "Error",
                      text: response.data.message?response.data.message:"Something went wrong",
                      icon: "error",
                      position: "top",
                  });
              }
        } catch (error) {
            setLoader(false);
            console.error("Error fetching checkout data:", error);
            let _errorMsg: string = "";
            let _errorHtml:string="";
            if (error instanceof AxiosError) {
            const _i:ApiError=error.response?.data;
            _errorMsg = _i.message;
            let a ="<ul>";
            _i.errors.map((item,index)=>{
                a=a+"<li>"+Object.values(item)+"</li>"
            });
            a=a+"</ul>";
            _errorHtml= a.length>10?a:_errorHtml;
    
            } else {
            _errorMsg = "Something went wrong! Try after sometime";
            }
            
            Swal.fire({
            title: "Error",
            text: _errorMsg,
            html:_errorHtml,
            icon: "error",
            position: "top",
            });
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
            const { name, value } = e.target;
            
            if(name==="epu"){
                setGotFirstOtp(false);
                setEpu(value.trim());
            }
            if(name==="pass"){
                setPass(value.trim());
                setPassError(null);
                setformData(prev => {
                    return { 
                        ...prev, 
                        newPassword:value.trim()
                    }
                });               
            }
            if(name==="confirmPass"){
                setConfirmPass(value.trim());
                setConfirmPassError(null);
            }
            if(name==="otp"){
                setOtp(value.trim());
                setformData(prev => {
                    return { 
                        ...prev, 
                        otp:value.trim()
                    }
                });
            }
            
    };

    const handleGetOtp  =()=>{
        let _epu =epu;
        let _formData:ForgotPasswordFormData=initialFormData;
        if(isEmail(_epu)){
            _formData.email=_epu;

        }
        else if(isPhoneNumber(_epu)){
            _formData.phoneNumber=_epu;

        }
        else if(_epu.length>3){
            _formData.username=_epu;
        }
        
        if((_formData.email&&_formData.email?.length>0) ||( _formData.phoneNumber&&_formData.phoneNumber?.length>0) || (_formData.username && _formData.username?.length>3)){
            getForgotPassOTP();
            
        }
        else{
            setEpuError("Please Enter Valid Emial/Phone-number/Username");
        }
        

    }

    const handleResendOtp = () =>{
        resendForgotOTP();
    }
    
    const handleVerifyOtp = () =>{
        debugger;
        if(isDigits(otp,4,4)){
            setOtpError(null);
            verifyForgotPassOTP();
        }
        else{
            setOtpError("Enter valid OTP");
        }
        
    }

    const handleUpdatePass = () =>{
        let _passError = validatePassword(pass);
        if(pass!=confirmPass){
            setPassError("Password and confirm password are not the same!");
            setConfirmPass("Password and confirm password are not the same!");
        }
        else if(_passError!=""){
            setPassError(_passError);
        }
        else{
            setPassError(null);
            resetForgotPassword();
        }
        
    }
    

    return (
        <div className="App">
      {loader && <Loader />}
      <form className="loginForm glass">
        <div className="form-group">
          <h2>Forgot Password</h2>
        </div>
        {!otpVerified && <div>
            <div className="form-group">
            <input
                className="form-control"
                type="text"
                name="epu"
                title={epu}
                value={epu}
                placeholder='Email/Mobile/Username'
                onChange={(e)=>{ setEpuError(null);handleChange(e);}}
            />
            {epuError && <p className="error">{epuError}</p>}
            </div>
            {!gotFirstOtp && <button
            className="btn btn-primary"
            type="button"
            onClick={handleGetOtp}
            >
            GetOTP
            </button>}
            {gotFirstOtp && resendOtp && <button
            className="btn btn-primary"
            type="button"
            onClick={handleResendOtp}
            >
            Resend OTP
            </button>}
            {gotFirstOtp && !resendOtp && <p style={{textAlign:'center'}}>
                {"Resend OTP available after " +otpResendTimeLeft +" seconds" }
            </p>}
            {gotFirstOtp && <div className="form-group">
            <br />
            <input
                className="form-control"
                type="text"
                name="otp"
                placeholder='Enter OTP'
                title={otp}
                value={otp}
                onChange={(e)=>{setOtpError(null);handleChange(e);}}
            />
            {otpError && <p className="error">{otpError}</p>}
            </div>}
            { gotFirstOtp && <button
            className="btn btn-primary"
            type="button"
            onClick={handleVerifyOtp}
            >
            Verify OTP
            </button>}
            </div>}
         {otpVerified && <div>
            <div className="form-group">
            <input
                className="form-control"
                type="password"
                name="pass"
                title={pass}
                value={pass}
                placeholder='Enter Password'
                onChange={(e)=>{ handleChange(e);}}
            />
            {passError && <p className="error">{passError}</p>}
            </div>
            <div className="form-group">
            <input
                className="form-control"
                type="password"
                name="confirmPass"
                title={confirmPass}
                value={confirmPass}
                placeholder='Enter Confirm Password'
                onChange={(e)=>{ handleChange(e);}}
            />
            {confirmPassError && <p className="error">{confirmPassError}</p>}
            <button
            className="btn btn-primary"
            type="button"
            onClick={handleUpdatePass}
            >
            Update Password
            </button>
            </div>

         </div>}

      </form>
    </div>
    )
}

export default ForgotPassword