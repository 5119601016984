import React, { useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { initialLoginData, loginRequest } from "../store/auth/authSlice";
import { loginSchema } from "../utils/validation";
import { z } from "zod";
import { useAppDispatch } from "../hooks/useRedux";
import Loader from "../components/loader/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const Login: React.FC = () => {
  const authData = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const [formData, setFormData] =
    useState<z.infer<typeof loginSchema>>(initialLoginData);
  const [errors, setFormErrors] = useState<{
    [key: string]: string | undefined;
  }>({});

  const navigate = useNavigate();

  const emailRegex = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
  const mobileRegex = /^\d{10}$/;

  const isEmail = (email: string): boolean => {
    if (emailRegex.test(email)) {
      return true;
    }
    return false;
  };
  const isPhoneNumber = (phoneNumber: string): boolean => {
    if (mobileRegex.test(phoneNumber)) {
      return true;
    }
    return false;
  };
  const validate = () => {
    try {
      loginSchema.parse(formData);
      // If validation succeeds, clear any previous errors
      setFormErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        // If validation fails, update formErrors with validation errors
        const errorMap: { [key: string]: string } = {};
        error.errors.forEach((err) => {
          if (err.path) {
            errorMap[err.path[0]] = err.message;
          }
        });
        setFormErrors(errorMap);
        return false;
      }
      throw error; // Throw any other unexpected errors
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    debugger;
    const { name, value } = e.target;
    if (name === "contact") {
      if (isEmail(value)) {
        setFormData((prevState) => ({
          ...prevState,
          email: value,
          username: "",
          phoneNumber: "",
        }));
      } else if (isPhoneNumber(value)) {
        setFormData((prevState) => ({
          ...prevState,
          phoneNumber: value,
          email: "",
          username: "",
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          username: value,
          email: "",
          phoneNumber: "",
        }));
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    debugger;
    e.preventDefault();
    if (validate()) {
      dispatch(loginRequest({ reqData: formData, navigate }));
    }
  };

  const handleCreateAccount = (): void => {
    navigate("/signup");
  };

  const handleForgotPassword = ():void =>{
    navigate("/forgotpassword");
  }

  return (
    <div className="App">
      {authData.loading && <Loader />}
      <form className="loginForm glass" onSubmit={handleSubmit}>
        <div className="form-group">
          <h2>Login</h2>
        </div>

        <div className="form-group">
          <label>Email Id/Mobile Number</label>
          <input
            className="form-control"
            type="text"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
          />
          {errors.contact && <p className="error">{errors.contact}</p>}
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            className="form-control"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && <p className="error">{errors.password}</p>}
        </div>
        <a style={{color:"blue",textDecoration:"underline"}} onClick={handleForgotPassword}>Forgot Password </a>

        <button className="btn btn-primary" type="submit">
          Login
        </button>

        <button
          className="btn btn-primary"
          type="button"
          onClick={handleCreateAccount}
        >
          Create Account
        </button>
      </form>
    </div>
  );
};

export default Login;
