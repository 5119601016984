import { CheckoutRequest, CheckoutResponse } from '../store/interface';
import { dotnetServiceAxios } from './axiosInstances';
//import axios from './_dotnetConfiguration';

export const  Checkout = async(req:CheckoutRequest) =>{
    return await dotnetServiceAxios.post<any>(`/Payment/Checkout`,req);
}

export const ValidatePayment = async (data:any) =>{
    return await dotnetServiceAxios.post<CheckoutResponse>(`/Payment/ValidatePayment`,data);
}