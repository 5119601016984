
import { LoginData } from '../store/interface';
//import axios from './_nodeConfigurations';
import { nodeServiceAxios } from './axiosInstances';


export const downloadApk = async (): Promise<void> => {
    try {
        const response = await nodeServiceAxios.get('/master/download-app', {
        responseType: 'blob', // Ensure the response is a Blob for file download
        });

        // Create a download link programmatically
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'YouEvolve.apk'); // Specify the file name
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading APK:', error);
    }
};