import { Mentadisorder } from '../store/interface';
//import axios from './_dotnetConfiguration';
import { dotnetServiceAxios } from './axiosInstances';

export const getMentalHealthIssuesAndItsTypes = async() =>{
    
    return await dotnetServiceAxios.get<Mentadisorder[]>('/HealthIssue/GetMentalHealthIssuesAndItsTypes');
}




