import { LeaderboardResponse } from '../store/interface';
import { InstitutionalResponse} from '../store/interface';
import { dotnetServiceAxios } from './axiosInstances';
//import axios from './_dotnetConfiguration';


export const getLeaderboard = async() =>{
  debugger;
  return await dotnetServiceAxios.get<LeaderboardResponse>('/Leaderboard/GetNationalRankings');
}

export const getInstitutionalboard = async() =>{
  debugger;
  return await dotnetServiceAxios.get<InstitutionalResponse>('/Leaderboard/GetInstitutionalRankings');
}