// import axios from './_dotnetConfiguration';
import { dotnetServiceAxios } from './axiosInstances';
import { ApiResponse, ConsellorFilter, CounsellorByCode, GetAllCouncelors, SlotsResponse,CounsellorSlotData } from '../store/interface';

// export const getInstitutionalboard = async() =>{
//     debugger;
//     return await dotnetServiceAxios.get<InstitutionalResponse>('/Leaderboard/GetInstitutionalRankings');
//   }

export const  GetCouncellors = async(filter:ConsellorFilter) =>{
    debugger;
    return await dotnetServiceAxios.post<GetAllCouncelors>(`/Councellor/GetAllCouncellors`,filter);
}

export const GetAvailableSlotsByCounsellor = async(counsellorId:number) =>{
    debugger;
    return await dotnetServiceAxios.post<SlotsResponse>(`/Councellor/GetAvailableSlotsByCounsellor?counsellorId=${counsellorId}`);
}

export const BookAFreeSlot =async(bookingId:string,paymentDone:boolean)=>{
    debugger;
    return await dotnetServiceAxios.post<ApiResponse>(`Councellor/BookASlot?bookingId=${bookingId}&paymentDone=${paymentDone}`);
}

export const GetCounsellorByCode = async(counsellorCode:string)=>{
    return await dotnetServiceAxios.get<CounsellorByCode>(`Councellor/GetCouncellorsByCounsellorCode?code=${counsellorCode}`);
}

export const SaveTimeSlot = async(slotInfo:CounsellorSlotData)=>{
    return await dotnetServiceAxios.post<CounsellorByCode>(`Councellor/AddASlot`,slotInfo);
}

export const DeleteCounsellorSlot = async(bookingId:string)=>{
    return await dotnetServiceAxios.post<ApiResponse>(`Councellor/DeleteASlot?bookingId=${bookingId}`)
}

// export const  GetCouncellors = async() =>{
//     return await dotnetServiceAxios.get<counsellorDetails>('/Councellor/GetAllCouncellors');
// }