import { RegistrationRequestModel, SignupModel } from '../store/interface';
//import axios from './_nodeConfigurations';
import { nodeServiceAxios } from './axiosInstances';


export const signup = async(req: SignupModel) =>{
    debugger;
  return await nodeServiceAxios.post('/users/signup', {email:req.email,phoneNumber:req.phoneNumber});
}
export const verifyEmail = async(req: SignupModel) =>{
    return await nodeServiceAxios.post('/users/verify-email', {email:req.email,otp:req.emailOTP});
}
export const verifyPhone = async(req: SignupModel) =>{
    return await nodeServiceAxios.post('/users/verify-phone', {phoneNumber:req.phoneNumber,otp:req.phoneOTP});
}
export const resendEmailOTP = async(req: SignupModel) =>{
    return await nodeServiceAxios.post('/users/resend-email-otp', {email:req.email});
}
export const resendPhoneOTP = async(req: SignupModel) =>{
    return await nodeServiceAxios.post('/users/resend-phone-otp', {phoneNumber:req.phoneNumber});
}

export const registration = async(req:RegistrationRequestModel)=>{
    debugger;
    return await nodeServiceAxios.post('/users/register', {email:req.email,phoneNumber:req.phoneNumber,username:req.username,password:req.password,gender:req.gender,dob:req.dob,instituteCode:req.instituteCode});
}

