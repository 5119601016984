import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, AppDispatch } from "../store/index";
import {
  getOTP as getOTP_Slice,
  resendEmailOTP,
  resendPhoneOTP,
  updateSignup,
  verifyEmail,
  verifyPhone,
} from "../store/registration/registrationSlice";
import Swal from "sweetalert2";
import { SignupErrors } from "../store/interface";
import { FcApproval, FcSynchronize } from "react-icons/fc";
import Loader from "../components/loader/Loader";
import { MdNextPlan } from "react-icons/md";

function Signup() {
  const signup = useSelector((state: RootState) => state.signup);
  const dispatch: AppDispatch = useDispatch();

  const [errors, setErrors] = useState<SignupErrors>({});
  const initialTime = 300; //120; // 120 seconds for 2 minutes
  const [emailResendTimeLeft, setEmailResendTimeLeft] = useState(initialTime);
  const [phoneResendTimeLeft, setPhoneResendTimeLeft] = useState(initialTime);
  const [resendEmailOtp, setResendEmailOtp] = useState<boolean>(false);
  const [resendPhoneOtp, setResendPhoneOtp] = useState<boolean>(false);
  const [verifyEmailBtn, setVerifyEmailBtn] = useState<boolean>(false);
  const [verifyPhoneBtn, setVerifyPhoneBtn] = useState<boolean>(false);

  useEffect(() => {
    if (emailResendTimeLeft > 0) {
      const emailTimer = setTimeout(() => {
        setEmailResendTimeLeft(emailResendTimeLeft - 1);
      }, 1000);
      return () => clearTimeout(emailTimer);
    } else {
      setResendEmailOtp(true);
    }
  }, [emailResendTimeLeft]);

  useEffect(() => {
    if (phoneResendTimeLeft > 0) {
      const phoneTimer = setTimeout(() => {
        setPhoneResendTimeLeft(phoneResendTimeLeft - 1);
      }, 1000);
      return () => clearTimeout(phoneTimer);
    } else {
      setResendPhoneOtp(true);
    }
  }, [phoneResendTimeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateSignup({ [name]: value }));
  };

  const validate = (): boolean => {
    if (!(signup.email || signup.phoneNumber)) {
      Swal.fire({
        text: "Please provide email or mobile info",
        icon: "info",
        position: "top",
      });
      return false;
    }
    debugger;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/; // Example regex for a 10-digit mobile number
    const _errors: SignupErrors = {};

    if (signup.phoneNumber && !mobileRegex.test(signup.phoneNumber)) {
      _errors.phoneNumber = "Invalid mobile number";
    }

    if (signup.email && !emailRegex.test(signup.email)) {
      _errors.email = "Invalid email address";
    }
    setErrors(_errors);
    console.log(errors);
    return Object.keys(_errors).length === 0;
  };

  const getOTP = () => {
    if (validate()) {
      dispatch(getOTP_Slice({ reqData: signup }));
      //navigate('/signupOTP');
    }
  };
  const verifyOTP = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateSignup({ [name]: value.trim() }));
    if (value.trim().length > 3) {
      if (name === "phoneOTP") {
        setVerifyPhoneBtn(true);
      } else if (name === "emailOTP") {
        setVerifyEmailBtn(true);
      }
    }
  };

  const verifyApi = () => {
    if (
      !signup.isEmailVerified &&
      signup.emailOTP.toString().trim().length > 3 &&
      validateEmailOTP()
    ) {
      debugger;
      dispatch(verifyEmail({ reqData: signup }));
      setVerifyEmailBtn(false);
    }
    if (
      !signup.isPhoneVerified &&
      signup.phoneOTP.toString().trim().length > 3 &&
      validatePhoneOTP()
    ) {
      dispatch(verifyPhone({ reqData: signup }));
      setVerifyPhoneBtn(true);
    }
  };

  const validateEmailOTP = () => {
    debugger;
    const fourDigistsRegex = /^\d{4}$/;
    if (
      signup.emailOTP.toString().trim().length > 0 &&
      !fourDigistsRegex.test(signup.emailOTP.toString().trim().toString())
    ) {
      const msg = "Invalid email OTP";
      setErrors((prevState) => ({
        ...prevState,
        emailOTP: msg,
      }));
      return false;
    }
    setErrors((prevState) => ({
      ...prevState,
      emailOTP: undefined,
    }));
    return true;
  };

  const validatePhoneOTP = () => {
    const fourDigistsRegex = /^\d{4}$/;
    if (
      signup.phoneOTP.toString().trim().length > 0 &&
      !fourDigistsRegex.test(signup.phoneOTP.toString().trim())
    ) {
      const msg = "Invalid Mobile OTP";
      setErrors((prevState) => ({
        ...prevState,
        phoneOTP: msg,
      }));
      return false;
    }
    setErrors((prevState) => ({
      ...prevState,
      phoneOTP: undefined,
    }));
    return true;
  };

  const handleResendEmailOtp = () => {
    debugger;
    setEmailResendTimeLeft(initialTime);
    setResendEmailOtp(false);
    dispatch(resendEmailOTP({ reqData: signup }));
  };

  const handleResendPhoneOtp = () => {
    debugger;
    setPhoneResendTimeLeft(initialTime);
    setResendPhoneOtp(false);
    dispatch(resendPhoneOTP({ reqData: signup }));
  };

  const handleProceed = () => {
    navigate("/registration");
  };

  return (
    <div className="App">
      {signup.loading && <Loader />}
      {!signup.proceedToOTP && (
        <form className="glass loginForm">
          <div className="form-group">
            <h2>New Account</h2>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Mobile Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={signup.phoneNumber}
                  onChange={handleChange}
                />
                {errors.phoneNumber && (
                  <p className="error">{errors.phoneNumber}</p>
                )}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label>Email Id</label>
                <input
                  type="text"
                  name="email"
                  value={signup.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
            </div>
          </div>
          <button className="btn btn-primary" type="button" onClick={getOTP}>
            Proceed
          </button>
        </form>
      )}
      {signup.proceedToOTP && (
        <form className="glass loginForm">
          <div className="form-group">
            <h2>New Account</h2>
          </div>

          <div className="form-group row">
            {signup.phoneNumber !== null &&
              signup.phoneNumber !== undefined &&
              signup.phoneNumber.trim() !== "" && (
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Mobile OTP</label>
                    <div className="row">
                      <div className="col-sm-9">
                        {" "}
                        <input
                          disabled={signup.isPhoneVerified}
                          type="text"
                          style={{ width: "auto" }}
                          name="phoneOTP"
                          value={signup.phoneOTP}
                          onChange={verifyOTP}
                        />
                      </div>
                      {!signup.isPhoneVerified && verifyPhoneBtn && (
                        <div className="col-sm-3" onClick={verifyApi}>
                          <MdNextPlan
                            style={{ color: "#001285" }}
                            title="Go"
                            size={35}
                          />
                        </div>
                      )}
                      {signup.isPhoneVerified && (
                        <div className="col-sm-3">
                          <FcApproval title="Mobile Verified" size={35} />
                        </div>
                      )}
                      {resendPhoneOtp
                        ? !signup.isPhoneVerified &&
                          !verifyPhoneBtn && (
                            <div
                              className="col-sm-3"
                              onClick={handleResendPhoneOtp}
                            >
                              <FcSynchronize
                                title="Resend Mobile OTP"
                                size={35}
                              />
                            </div>
                          )
                        : !signup.isPhoneVerified &&
                          !verifyPhoneBtn && (
                            <div
                              title="Resend Mobile OTP in"
                              style={{ margin: "auto" }}
                              className="col-sm-3"
                            >
                              {formatTime(phoneResendTimeLeft)}
                            </div>
                          )}
                    </div>
                    {errors.phoneOTP && (
                      <p className="error">{errors.phoneOTP}</p>
                    )}
                  </div>
                </div>
              )}
            {signup.email !== null &&
              signup.email !== undefined &&
              signup.email.trim() !== "" && (
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Email OTP</label>
                    <div className="row">
                      <div className="col-sm-9">
                        <input
                          disabled={signup.isEmailVerified}
                          type="text"
                          style={{ width: "auto" }}
                          name="emailOTP"
                          value={signup.emailOTP}
                          onChange={verifyOTP}
                        />
                      </div>
                      {!signup.isEmailVerified && verifyEmailBtn && (
                        <div className="col-sm-3" onClick={verifyApi}>
                          <MdNextPlan
                            style={{ color: "#001285" }}
                            title="Go"
                            size={35}
                          />
                        </div>
                      )}
                      {signup.isEmailVerified && (
                        <div className="col-sm-3">
                          <FcApproval title="Email Verified" size={35} />
                        </div>
                      )}
                      {resendEmailOtp
                        ? !signup.isEmailVerified &&
                          !verifyEmailBtn && (
                            <div
                              className="col-sm-3"
                              onClick={handleResendEmailOtp}
                            >
                              <FcSynchronize
                                title="Resend Email OTP"
                                size={35}
                              />
                            </div>
                          )
                        : !signup.isEmailVerified &&
                          !verifyEmailBtn && (
                            <div
                              title="Resend Email OTP in"
                              style={{ margin: "auto" }}
                              className="col-sm-3"
                            >
                              {formatTime(emailResendTimeLeft)}
                            </div>
                          )}
                    </div>
                    {errors.emailOTP && (
                      <p className="error">{errors.emailOTP}</p>
                    )}
                  </div>
                </div>
              )}
          </div>

          {(signup.isPhoneVerified || signup.isEmailVerified) && (
            <button onClick={handleProceed} type="button">
              Proceed
            </button>
          )}
        </form>
      )}
    </div>
  );
}

export default Signup;
