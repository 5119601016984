import React from 'react';
import './information.css';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

const Information: React.FC = () => {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="d-flex text-center justify-cotent-center">
                <h1 className="mt-5">Information</h1>
                </div>
                <hr style={{border:"3px solid"}}/>
                <div className="md-mb-4 md-p-5">
                    
                    <div>
                        <ul>
                            <li>
                            <h4 className="text-start">YouEvolve's Parent company is : Rijuvalika Technologies Private Limited</h4>
                            </li>
                            <br />
                            <li>
                                <div className='d-flex '>
                                    <h4>Office address : </h4>
                                    <h4>
                                        <div>&nbsp; Rijuvalika Technologies Private Limited,</div>
                                        <div>&nbsp; Amarnath keshri bhawan</div>
                                        <div>&nbsp; BabuBank, JhaJha</div>
                                        <div>&nbsp; Jamui, Bihar</div>
                                        <div>&nbsp; PinCode - 811308</div>
                                    </h4>

                                </div>
                                
                            </li>
                            <br />
                            <li>
                                <div className='d-flex '>
                                    <h4>Contact details : </h4>
                                    <h4>
                                        <div>&nbsp; Whatsapp us @ 9709121811</div>
                                        <div>&nbsp; Call us @ 9229857299</div>
                                        <div>&nbsp; Mail us @ <span><a href="mailto:rijuvalikatechnologiespvtltd@gmail.com">rijuvalikatechnologiespvtltd@gmail.com</a></span> </div>
                                        <div>&nbsp; CEO @ <span><a href="mailto:ceo@rijuvalikatechnologies.in">ceo@rijuvalikatechnologies.in</a></span> </div>
                                        <div>&nbsp; Admin @ <span><a href="mailto:admin@youevolve.in">admin@youevolve.in</a></span></div>
                                    </h4>

                                </div>
                                
                            </li>
                            <br />


                        </ul>
                        
                    </div>
               
                </div>
            </div>

           
            <Footer />
        </div>
    )
}

export default Information