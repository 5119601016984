
//#region validation region
export const isEmail = (email: string): boolean=> {
    const emailRegex = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
}

export const isPhoneNumber = (phoneNumber: string): boolean=> {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(phoneNumber);
}

export const isDigits = (str:string,minChar:number|null =null,maxChar:number|null=null):boolean =>{
    if(minChar ===null || str.length>=minChar){
        if(maxChar === null || str.length<=maxChar){
            const reg = /^[0-9 ]*$/;
            return reg.test(str);
        }
    }
    return false
}

export const validatePassword = (str:string):string =>{
    if(str.length<8){
        return 'Password should have minimun 8 char length';
    }
    else if(!validateCustomRegex(str,/[A-Z]/)){
        return 'Password must contain at least one uppercase letter';
    }
    else if(!validateCustomRegex(str,/[a-z]/)){
        return 'Password must contain at least one lowercase letter';
    }
    else if(!validateCustomRegex(str,/[0-9]/)){
        return 'Password must contain at least one number';

    }
    else if(!validateCustomRegex(str,/[!@#$%^&*]/)){
        return 'Password must contain at least one special character';
    }
    else{
        return "";
    }

}

export const validateCustomRegex = (str:string,reg:RegExp):boolean =>{
    return reg.test(str);
}


//#endregion



//#region formatiing region

const monthNames=["Jan",  "Feb",  "Mar",  "Apr",  "May",  "Jun",  "Jul",  "Aug",  "Sep",  "Oct",  "Nov",  "Dec"];
export const formatDate = (date:Date)=> {
    let _day =date.getDate();
    let _month = monthNames[date.getMonth()];
    let _year = date.getFullYear();
    return _day+"-"+_month+"-"+_year;
}

export const formatTime =(time24:string) =>{
const [hours, minutes, seconds] = time24.split(':');
let formattedHours = parseInt(hours, 10);
const amPm = formattedHours >= 12 ? 'PM' : 'AM';

formattedHours %= 12;
formattedHours = formattedHours || 12;

return `${formattedHours}:${minutes}:${seconds.split('.')[0]} ${amPm}`;
}

//endregion