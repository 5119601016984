//import { createAxiosInstance } from './axiosConfig';
import axios, { AxiosInstance } from 'axios';
import { navigateTo } from '../utils/navigationHelper';
import Swal from 'sweetalert2';



//const node_URL:string = 'https://chat.youevolve.in/api/v1'; 
const node_URL:string = 'https://chat-test.youevolve.in/api/v1'; 
//const node_URL:string =  'http://localhost:8080/api/v1'; 

//const dotnet_URL:string = 'https://api.youevolve.in/api'; 
const dotnet_URL:string = 'https://api-test.youevolve.in/api'; 
//const dotnet_URL:string =  'http://localhost:5000/api/';

const getToken = () => localStorage.getItem('accessToken');

export const nodeServiceAxios = axios.create({
  baseURL: node_URL,
});

export const dotnetServiceAxios = axios.create({
  baseURL: dotnet_URL,
});

// Setup interceptors
const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    config => {
        const token = getToken();
        if (token) {
            config.headers['X-Authorization'] = token;
        }
        
        return config;
      },
      error => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        debugger;
      if (error.response?.status === 401) {
        Swal.fire({
            title: "Info",
            text: "Your session expired, please sign in again.",
            icon: "info",
            confirmButtonColor: "#0d6efd",
            confirmButtonText:"OK"
        }).then((result) => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            navigateTo('/login');
        });
        
      }
      return Promise.reject(error);
    }
  );
};

// Apply to all Axios instances
setupInterceptors(nodeServiceAxios);
setupInterceptors(dotnetServiceAxios);

