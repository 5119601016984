import React from "react";
import "./Footer.css";
import TermsAndConditions from "../../pages/termsAndConditions/TermsAndConditions";
const Footer: React.FC = () => {
  return (
    <div className="footerCls">
      <div className="footer bg-black px-4 ">
        <div className="container-fluid text-light py-4">
          <div className="row text-light gx-3">
            <div className="First_part col-12 col-md-6 col-lg-3 mb-5">
              <a href="/about" className="text-decoration-none text-light">
                <h2 className="aboutAndFaqs  fs-lg-2  text-secondary fw-normal text-start">
                  ABOUT & FAQS
                </h2>
              </a>
             
            </div>
            <div className="Second_part col-12 col-md-6 col-lg-2 mb-5">
              <a href="/information" className="text-decoration-none text-light">
                <h2 className="informationCenter  text-secondary fw-normal text-start">
                  INFORMATION CENTER
                </h2>
              </a>
              <a
                href="/termsAndConditions"
                className="text-decoration-none text-light"
              >
                <h2 className="termAndCondition  text-secondary fw-normal text-start">
                  TERMS & CONDITION
                </h2>
              </a>
              <a
                href="/privacyPolicy"
                className="text-decoration-none text-light"
              >
                <h2 className="privacyPolicy text-secondary fw-normal text-start">
                  PRIVACY POLICY
                </h2>
              </a>
              <a
                href="/refundPolicy"
                className=" text-decoration-none text-light"
              >
                <h2 className="footer-policy text-secondary fw-normal text-start">
                  REFUND POLICY
                </h2>
              </a>
              <a
                href="/cancellationPolicy"
                className=" text-decoration-none text-light"
              >
                <h2 className="footer-policy text-secondary fw-normal text-start">
                  CANCELLATION POLICY
                </h2>
              </a>
            </div>
            <div className="Third_part col-12 col-md-6 col-lg-3 mb-5">
              <h2 className="AllRightsResered  text-secondary fw-normal text-start">
                All Rights Reserved
              </h2>
              <h2 className="rijuvalikaTechPrivLim text-secondary fw-normal text-start">
                &copy; rijuvalika technologies private limited
              </h2>
            </div>
            <div className="fourth_part col-12 col-md-6 col-lg-4 mb-5">
              <a
                href="mailto:admin@youevolve.in"
                className="mailto text-decoration-none text-light"
              >
                <h2 className="adminYoue  text-secondary fw-normal text-start">
                  admin@youevolve.in
                </h2>
              </a>
              <a
                href="mailto:rijuvalikatechnologiespvtltd@gmail.com"
                className="text-decoration-none text-light"
              >
                <h2 className="rijuvalikaTechnolog  text-secondary fw-normal text-start">
                  rijuvalikatechnologiespvtltd@gmail.com
                </h2>
              </a>
              <h2 className="allinformation  text-secondary fw-normal text-start">
                Rijuvalika Technologies Pvt Ltd
                <br />
                Amarnath Keshri Bhawan
                <br />
                Babubank, JhaJha
                <br />
                Jamui, Bihar 811308
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
