import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import './counsellorSlotChanges.css';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Swal from 'sweetalert2';
import { DeleteCounsellorSlot, GetAvailableSlotsByCounsellor, GetCounsellorByCode, SaveTimeSlot } from '../../api/councellor';
import { CounsellorSlotData, CounsellorByCode, CounsellorDetails, WeeksInfo, SlotsResponse, DateSlot, ApiError } from '../../store/interface';
import Loader from '../../components/loader/Loader';
import counsellorImage from "../../assets/images/counselarNew.png";
import '../counsellorScreen/counsellorInformation/counsellorInformation.css';
import { __DO_NOT_USE__ActionTypes } from 'redux';
import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
import { FaForward } from 'react-icons/fa';
import { formatDate, formatTime } from '../../utils/helper';
import { AxiosError } from 'axios';

const initialCounsellorDetails:CounsellorDetails ={
    id : 0 ,
    counsellorId : "" ,
    name : "" ,
    organisationId : 0 ,
    isPaid : false ,
    feePerSession : 0 ,
    expertise : "" ,
    bio : "" ,
    experience : 0 ,
    imagePath : "" ,
}

const initialCouncellor:CounsellorByCode = {
    statusCode: 0,
    data: initialCounsellorDetails,
    message: "",
    isSuccessful: false,
}

const initialSlots:SlotsResponse={
    statusCode:null,
    data:null,
    message:null,
    isSuccessful:null
}

const CounsellorAddSlot: React.FC = () => {
    const [counsellorCode, setCounsellorCode] = useState("");
    const [gotDetails, setGotDetails] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false);
    const [councellorsdata, setCouncellorsdata] = useState<CounsellorByCode>(initialCouncellor);
    const [counsellorId, setCounsellorId] = useState(0);
    const [timeSlot, setTimeSlot] = useState<string>('');
    const [duration, setDuration] = useState<number|null>(null);

    const [slots, setSlots] = useState<SlotsResponse>(initialSlots);
    const [month, setMonth] = useState<number>(new Date().getMonth());
    const [weeksInfo, setWeeksInfo] = useState<WeeksInfo[]|null>();
    const [monthArr, setMonthArr] = useState<number[]>([]);
    const [totalSlots, setTotalSlots] = useState<{id:number,date:string,startsAt:string,duration:string;slotBookingId:string}[]>();
    const [weekSlots, setWeekSlots] = useState<{id:number,date:string,startsAt:string,duration:string;slotBookingId:string}[]>();
    const [monthNames,setMonthNames] =useState<string[]>(["Jan",  "Feb",  "Mar",  "Apr",  "May",  "Jun",  "Jul",  "Aug",  "Sep",  "Oct",  "Nov",  "Dec"] );
    
    useEffect(() => {
        const _currentYear:number=new Date().getFullYear();
        const _currentMonth:number=new Date().getMonth();
        getWeeksInMonth(_currentYear,_currentMonth);
    
    
        let arr=[];
        let currentMonth=new Date().getMonth();
        for(let i=0;i<12;i++){
            arr.push(currentMonth);
            let nextMonth=currentMonth+1;
            if(currentMonth==11){
                nextMonth=0;
            }
            currentMonth=nextMonth;   
    
        };
        setMonthArr(arr);
    
      }, []);

    const fetchCounsellorByCode = async () => {
      try {
        setLoader(true);
        const response = await GetCounsellorByCode(counsellorCode);
        setLoader(false);
        debugger;
        setCouncellorsdata(response.data);
        fetchCounsellorSlots(response.data.data.id);
        setGotDetails(true);
        debugger;
        setCounsellorId(response.data.data.id);
      } catch (error) {
        setLoader(false);
        setGotDetails(false);
        console.error("Error fetching leaderboard data:", error);
      }
    };

    const fetchCounsellorSlots = async (counsellorId:number) => {
        try {
          setLoader(true);
          debugger;
          const response = await GetAvailableSlotsByCounsellor(counsellorId);
          setLoader(false);
          if(response.data.data && response.data.data.length>0){
                const futureSlots=response.data.data.filter(compareWithCurrentDate);
                setSlots(prevState=>({...prevState,data:futureSlots}));
                
                let i=0;
                let _totalSlots:{id:number,date:string,startsAt:string,duration:string;slotBookingId:string}[]=[];
                futureSlots?.map(x=>{ 
                x.timeAndDurations.map(y=>{
                    i=i+1;
                    _totalSlots.push({id:i,date:x.date,startsAt:y.timeSlot,duration:y.duration+"",slotBookingId:y.bookingId})
                })
            });
            setTotalSlots(_totalSlots);
          }
        } catch (error) {
          setLoader(false);
          console.error("Error fetching counsellor slots data:", error);
        }
    };

    const removeCounsellorSlot = async (bookingId:string) =>{
        try {
            setLoader(true);
            const response = await DeleteCounsellorSlot(bookingId);
            setLoader(false)
            if(response.data.statusCode===200){
                Swal.fire({
                    title: "Success",
                    text: response.data.message?response.data.message:"Slot removed successfully!",
                    icon: "success",
                    position: "top",
                    confirmButtonColor: "#0d6efd",
                    confirmButtonText:"OK",
                }).then(() => {
                    fetchCounsellorSlots(councellorsdata.data.id);
                });
            }
            else{
                Swal.fire({
                    title: "Error",
                    text: response.data.message?response.data.message:"Something went wrong",
                    icon: "error",
                    position: "top",
                });
            }
        } catch (error) {
            setLoader(false);
            console.error("Error remove counsellor slot data:", error);
            let _errorMsg: string = "";
            let _errorHtml:string="";
            if (error instanceof AxiosError) {
                const _i:ApiError=error.response?.data;
                _errorMsg = _i.message;
                let a ="<ul>";
                _i.errors.map((item,index)=>{
                    a=a+"<li>"+Object.values(item)+"</li>"
            });
            a=a+"</ul>";
            _errorHtml= a.length>10?a:_errorHtml;
    
            } else {
                _errorMsg = "Something went wrong! Try after sometime";
            }
            
            Swal.fire({
                title: "Error",
                text: _errorMsg,
                html:_errorHtml,
                icon: "error",
                position: "top",
            });
        }

    }

    const saveSlotInfo = async (slotInfo:CounsellorSlotData) => {
        try {
          setLoader(true);
          const response = await SaveTimeSlot(slotInfo);
          debugger;
          setLoader(false);
          if(response.data.statusCode===200){
            Swal.fire({
                title: "Success",
                text: "Your slot added successfully.",
                icon: "success",
                position: "top",
            });
            setTimeSlot('');
            setDuration(0);
            fetchCounsellorSlots(councellorsdata.data.id);
          }
          else{
            Swal.fire({
                title: "Error",
                text: response.data.message?response.data.message:"Something went wrong",
                icon: "error",
                position: "top",
            });
          }
          
            
        } catch (error) {
          setLoader(false);
          Swal.fire({
                title: "Error",
                text: "Enter In saving the slot, please try again after sometime",
                icon: "error",
                position: "top",
            });
          
        }
      };
  

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        if(name==="counsellorCode"){
            setCounsellorCode(value.toUpperCase());
        }
        if(name==="timeSlot"){
            setTimeSlot(value);
        }
        if(name==="duration"){

            const _duration = parseInt(value);
            if(_duration>0 && _duration<=500){
                setDuration(_duration);
            }
            
        }
    };

    const handleGetDetails = (): void => {
        if(!(counsellorCode.length>2)){
            Swal.fire({
                    title: "Input Error",
                    text: "Enter Valid Code",
                    icon: "error",
                    position: "top",
                });
        }
        else{
            fetchCounsellorByCode();
        }
            
    };
    const handleAddSlot = ():void =>{
        if(!(counsellorId>0) || (timeSlot===null || timeSlot===undefined || timeSlot==='') || (duration===null || duration===undefined || duration<=0) ){
            Swal.fire({
                    title: "Input Error",
                    text: "Enter Valid Data",
                    icon: "error",
                    position: "top",
                });
        }
        else{
            const _slotInfo:CounsellorSlotData={
                counsellorId : counsellorId,
                timeSlot : timeSlot,
                durationInMinutes :duration, 
                summary : ""
            };
            debugger;
            saveSlotInfo(_slotInfo);
        }

    }

    const compareWithCurrentDate=(data:DateSlot)=>{
        let a =new Date(data.date);
        let b = new Date();
        return a>=b;
    }
    
    const compareWithMonth=(data:DateSlot)=>{
        let a =new Date(data.date).getMonth();
        return a===month;
    }

    const handleLeftRightMonth = (direction:string)=>{
        let currentMontIndex=monthArr?.indexOf(month);
        let lastIndex = monthArr.length-1>=0?monthArr.length-1:0;
        if(direction=="Left"){
            if(month!=monthArr[0] ){
            let _month=monthArr[currentMontIndex-1];
            const _year=new Date().getMonth()<=_month?new Date().getFullYear():new Date().getFullYear()+1;
            setMonth(_month);
            setWeekSlots([]);
            getWeeksInMonth(_year,_month);
            }
        }
        else{
            if(month!=monthArr[lastIndex] ){
            let _month=monthArr[currentMontIndex+1];
            const _year=new Date().getMonth()<=_month?new Date().getFullYear():new Date().getFullYear()+1;
            setMonth(_month);
            setWeekSlots([]);
            getWeeksInMonth(_year,_month);
            }
        }
    };

    const handleViewWeekSlots=(weekIndex:number)=>{
        if(slots.data && slots.data.length>0 && slots.data.filter(compareWithMonth).length>0){
            const _year=new Date().getMonth()<=month?new Date().getFullYear():new Date().getFullYear()+1;
            const _weekArr =  weeksInfo && weeksInfo.length>0?weeksInfo[weekIndex].weeks:null;
            
            if(_weekArr){
            let startDate = new Date(_year,month,_weekArr[0].date);
            let endDate = new Date(_year,month,_weekArr[_weekArr.length-1].date);
            if(endDate>=new Date()){
                endDate.setDate(endDate.getDate() + 1);
                const _slotInfo  = totalSlots?.filter(obj => {
                    const objDate = new Date(obj.date);
                    return objDate >= startDate && objDate < endDate;
                });
                setWeekSlots(_slotInfo);
            
            }
            }
        }
    }

    const getWeeksInMonth=(year:number, month:number)=> {
    
        const weeks:any = [],
          firstDate = new Date(year, month, 1),
          lastDate = new Date(year, month + 1, 0),
          numDays = lastDate.getDate(),
          weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
      
        let dayOfWeekCounter = firstDate.getDay();
    
        // let _currentDate = new Date();
        // _currentDate.setDate(_currentDate.getDate() - 1);
        
        for (let date = 1; date <= numDays; date++) {
          //if(_currentDate < new Date(year,month,date)){
            if (dayOfWeekCounter === 0 || weeks.length === 0) {
              weeks.push([]);
            }
            let day = new Date(year,month,date).getDay()
            let weekDay= weekdays[day];
            let _push= {date:date,day:weekDay};
            if( weeks[weeks.length - 1]){
              weeks[weeks.length - 1].push(_push);
      
            }
            dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
          //}
        }
      
        const _setWeeks = weeks
          .filter((w:any[]) => !!w.length)
          .map((w:any[]) => ({
            weeks: w,
          }));
    
         setWeeksInfo(_setWeeks);
    
    
    };
    
    const slotsForWeek =(weekIndex:number)=>{
    let slotCount:number=0;
    if(slots.data && slots.data.length>0 && slots.data.filter(compareWithMonth).length>0){
        const _year=new Date().getMonth()<=month?new Date().getFullYear():new Date().getFullYear()+1;
        const _weekArr =  weeksInfo && weeksInfo.length>0?weeksInfo[weekIndex].weeks:null;
        
        if(_weekArr){
        let startDate = new Date(_year,month,_weekArr[0].date);
        let endDate = new Date(_year,month,_weekArr[_weekArr.length-1].date);
        if(endDate>=new Date()){
            endDate.setDate(endDate.getDate() + 1);
            const _slotInfo:{date:string,slot:string}[]=[];
            slots?.data.map(x=>{ 
            x.timeAndDurations.map(y=>{
                _slotInfo.push({date:x.date,slot:y.timeSlot})
            })
            });

            slotCount = _slotInfo.filter(obj => {
                const objDate = new Date(obj.date);
                return objDate >= startDate && objDate < endDate;
            }).length;
        }
        }
    }
    return slotCount;
    }

    const handleMonth=(e:React.ChangeEvent<HTMLSelectElement>)=>{
        const _month =parseInt(e.target.value);
        const _year=new Date().getMonth()<=_month?new Date().getFullYear():new Date().getFullYear()+1;
        setMonth(_month);
        setWeekSlots([]);
        getWeeksInMonth(_year,_month);

    }

    const handleRemoveSlot = (bookingId:string) =>{
         Swal.fire({
            title: "Are You Sure!",
            text: "Your slot will be removed.",
            icon: "info",
            confirmButtonColor: "#0d6efd",
            confirmButtonText:"OK",
            showDenyButton: true,
            denyButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                removeCounsellorSlot(bookingId);
            }
        });
    }

    
    return (
        <div className='pb-5'>
            <Header />
            {loader && <Loader/>}
            <div className='Container pt-5'>
            { !gotDetails && <div className="d-flex text-center justify-cotent-center px-5 py-5">
                   <div className='col-12'>
                        <div className="form-group row">
                            <div className=' center-element col-sm-12 col-md-6 col-xl-6'>
                                <input
                                    className="form-control element-width"
                                    type="text"
                                    placeholder='Enter Counsellor Code'
                                    name="counsellorCode"
                                    value={counsellorCode}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className=' center-element col-sm-12 col-md-6 col-xl-6'>
                                <button
                                    className="btn btn-primary btn-auto-width"
                                    type="button"
                                    onClick={handleGetDetails}
                                    >
                                    Get Details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}

                {gotDetails && 
                <div className='body-div div-div'>
                    

                    {/* basic information */}
                    <Fragment>
                        <div className="card mx-4">
                        <div className="m-3"><h3>Counsellor</h3></div>
                        <div className='mx-3'><hr /></div>
                        <div className="row">
                            <div className="center-element col-sm-12 col-md-6 col-xl-6 col-lg-6">
                                <div className='p-3 counsellor-image-div'>
                                    <div style={{borderRadius:"50%"}} className="counsellor-border">

                                    
                                        <img
                                            src={councellorsdata.data.imagePath ?councellorsdata.data.imagePath :counsellorImage}
                                            alt={councellorsdata.data.name}
                                            className='counsellor-img'
                                        />
                                    
                                    </div>
                                </div>
                            </div>
                            <div className="center-element col-sm-12 col-md-6 col-xl-6 col-lg-6">
                                <div style={{minWidth:"80%"}} className='p-3'>
                                    <div className='counsellor-table-div' style={{width:"-webkit-fill-available"}}>
                                        <table className='table'>
                                        <tbody>
                                            <tr>
                                            <th>Name</th>
                                            <td>{councellorsdata.data.name}</td>
                                            </tr>
                                            <tr>
                                            <th>Bio</th>
                                            <td>{councellorsdata.data.bio}</td>
                                            </tr>
                                            <tr>
                                            <th>Expertise</th>
                                            <td>{councellorsdata.data.expertise}</td>
                                            </tr>
                                            <tr>
                                            <th>Experience In Year(s)</th>
                                            <td>{councellorsdata.data.experience}</td>
                                            </tr>
                                            <tr>
                                            <th>Fee Per Session </th>
                                            <td>{councellorsdata.data.feePerSession?councellorsdata.data.feePerSession:0}₹ <span style={{color:"gray"}}> + tax</span></td>
                                            </tr>
                                            {councellorsdata.data.feePerSession && councellorsdata.data.feePerSession > 0 ?<tr>
                                            <th>Tax</th>
                                            <td><p>18% GST collected at checkout</p></td>
                                            </tr>:""}
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                        
                        </div>
                    </Fragment>
                    <br />
                    <br />
                    <br />
                    <br />
                    
                    {/* Add Slots */}
                    <Fragment>
                        <div className="card mx-4 pb-5">
                            <div className="m-3"><h3>Add Your Slot</h3></div>
                            <div className='mx-3'><hr /></div>
                            <div className="row">
                                <div className="center-element justify-center col-sm-12 col-md-5 col-xl-5 col-lg-5">
                                                
                                    <input
                                        className="form-control element-width"
                                        type="datetime-local"
                                        placeholder ='Enter Date Time'
                                        name="timeSlot"
                                        value={timeSlot}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="center-element col-sm-12 col-md-5 col-xl-5 col-lg-5">
                                    <input
                                        className="form-control element-width"
                                        type="number"
                                        placeholder='Enter Duration'
                                        name="duration"
                                        value={duration?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="center-element col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                    <button
                                        className="btn btn-primary btn-auto-width"
                                        type="button"
                                        onClick={handleAddSlot}
                                        >
                                        Add Slot
                                    </button>
                                </div>
                                <div className="center-element col-sm-12 col-md-6 col-xl-6 col-lg-6"></div>
                                <div className="center-element col-sm-12 col-md-6 col-xl-6 col-lg-6"></div>
                                <div className="center-element col-sm-12 col-md-6 col-xl-6 col-lg-6"></div>
                            </div>


                        </div>
                    </Fragment>
                    <br />
                    <br />
                    <br />
                    <br />
                    
                     {/* solt information */}
                    <Fragment>
                        <div className="card m-4">
                        <div className="m-3"><h3>Remove Your Slots</h3></div>
                        <div className='mx-3'><hr /></div>
                            <div className='dynamic-space-between '>
                            <div className='mx-3'>
                                <select name="month" value={month}  onChange={(e)=>handleMonth(e)} className="form-control pe-5 round rounded-pill select-border">
                                <option value="0">January {new Date().getMonth()<=0?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="1">February {new Date().getMonth()<=1?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="2">March {new Date().getMonth()<=2?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="3">April {new Date().getMonth()<=3?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="4">May {new Date().getMonth()<=4?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="5">June {new Date().getMonth()<=5?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="6">July {new Date().getMonth()<=6?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="7">August {new Date().getMonth()<=7?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="8">September {new Date().getMonth()<=8?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="9">October {new Date().getMonth()<=9?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="10">November {new Date().getMonth()<=10?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                <option value="11">December {new Date().getMonth()<=11?new Date().getFullYear():new Date().getFullYear()+1}</option>
                                </select>
                            </div>
                            <div className='d-flex mx-3 pb-3'>
                                <button onClick={()=>handleLeftRightMonth("Left")} style={{aspectRatio:1,display:"flex",alignItems:"center"}} className='mx-2 btn btn-outline-primary rounded-pill rounded-end'><BiSolidLeftArrow style={{fontSize:18}}/></button>
                                <button onClick={()=>handleLeftRightMonth("Right")} style={{aspectRatio:1,display:"flex",alignItems:"center"}} className='mx-2 btn btn-outline-primary rounded-pill rounded-start'><BiSolidRightArrow style={{fontSize:18}}/></button>
                            </div>
                            
                            </div>
                            <div className='m-3'><hr /></div>
                            <div className='mx-3 mb-3'>
                            {slots.data && slots.data.length>0 && slots.data.filter(compareWithMonth).length>0
                            ?<div>
                                <div className='dynamic-space-around'>
                                {weeksInfo && weeksInfo.length>0 &&
                                    weeksInfo.map((w,i)=>(
                                    <div key={i} className={slotsForWeek(i)>0?'p-2 counsellor-border':'p-2 counsellor-border no-slot-week'} >
                                        <div><h5>Week - {i+1}</h5></div>
                                        <div className='d-flex'>
                                        <div className='d-flex mx-2'>
                                            <div> <strong>Days :</strong></div>
                                            <div>
                                            &nbsp;{w.weeks.length}
                                            {/* {w.weeks.map((d,j)=>(
                                                <span className='mx-2' key={i+"-"+j}>{d.date}</span>
                                            ))} */}
                                            </div>
                                        </div>
                                        <div className='d-flex mx-2'>
                                            <div><strong>Slots :</strong></div>
                                            <div>&nbsp;{slotsForWeek(i)}</div>
                                        </div>
                                        </div>
                                        <div>
                                        <button style={{display:"flex",alignItems:"center",justifyContent:"center"}} disabled={slotsForWeek(i)>0?false:true} onClick={()=>handleViewWeekSlots(i)} className='btn btn-outline-primary rounded-pill'><FaForward /></button>
                                        </div>
                                    </div>
                                    ))
                                }
                                </div>
            
                                <div className='m-3'><hr /></div>
            
                                {weekSlots && weekSlots.length>0 && 
                                <div style={{alignItems:"center"}} className='overflow-auto d-flex mx-3 mb-5 p-5 counsellor-border'>
                                <table className='table slots-table'>
                                    <thead>
                                    <tr>
                                        <th ><div style={{borderRadius:"6px 0px 0px 6px",textWrap:"nowrap"}}>Id</div></th>
                                        <th ><div style={{borderRadius:"0px 0px 0px 0px",textWrap:"nowrap"}}>Date&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</div></th>
                                        <th ><div style={{borderRadius:"0px 0px 0px 0px",textWrap:"nowrap"}}>Starts At</div></th>
                                        <th ><div style={{borderRadius:"0px 0px 0px 0px",textWrap:"nowrap"}}>Duration</div></th>
                                        <th ><div style={{borderRadius:"0px 6px 6px 0px",textWrap:"nowrap"}}>Action</div></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        weekSlots.map((x,i)=>(
                                        <tr key={i}>
                                        <td>{i+1}</td>
                                        <td style={{width:"fit-content"}}>{formatDate(new Date(x.date))}</td>
                                        <td style={{width:"fit-content"}}>{formatTime(x.startsAt)}</td>
                                        <td>{x.duration+" Min"}</td>
                                        <td><span  
                                         onClick={()=>handleRemoveSlot(x.slotBookingId)}
                                         style={{background:"#00387d",color:"white"}} className='btn rounded-pill'>Remove</span></td>
                                        </tr>
            
                                        ))
                                    }
                                    </tbody>
                                </table>
                                </div>
                                }
                                
                            
                            </div>
                            : <div style={{textAlign:"center",textWrap:"pretty", width:"-webkit-fill-available",minHeight:"30vh",justifyContent:"center",alignItems:"center"}} className='d-flex m-3 my-5 counsellor-border'><h3>No Slots Available For This Month</h3></div>
                            }
                            </div>
            
            
                        </div>
                    </Fragment>
                    <br />
                    <br />
                    <br />
                    <br />





                </div>
                    }
            </div>
        </div>
    )
}

export default CounsellorAddSlot