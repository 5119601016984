import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import './about.css';

const About: React.FC = () => {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="d-flex text-center justify-cotent-center">
                <h1 className="mt-5">About</h1>
                </div>
                <hr style={{border:"3px solid"}}/>
                <div className="md-mb-4 md-p-5">
                    <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Mission</h2></div>
                    <div><h4 className="text-center">To empower individuals to prioritize their mental well-being by providing accessible, innovative, and personalized tools that foster emotional resilience, growth, and a sense of community.
                    </h4></div>

                    <br />
                    <br />
                    <br />

                    <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Vision</h2></div>
                    <div><h4 className="text-center">To create a world where mental health support is universally accessible, enabling everyone to thrive emotionally, socially, and spiritually through the seamless integration of technology and human connection.
                    </h4></div>

                    <br />
                    <br />

               
                </div>
            </div>

            <div className="container">
                <div className="d-flex text-center justify-cotent-center">
                <h1 className="mt-5">FAQs</h1>
                </div>
                <hr style={{border:"3px solid"}}/>
                <div className="md-mb-4 md-p-5">
                {/* general information */}
                <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2> General Information</h2></div>
                <br />
                <ol>
                    <li>
                        <h5>What is YouEvolve?</h5>
                    </li>
                    <ul><li><h6>YouEvolve is a mental health app designed to support individuals in their emotional, social, and spiritual well-being. The platform offers a wide range of art-based self-help tools, along with resources to help users manage their mental health effectively.</h6></li></ul>
                    <br />
                    <li className="mt-3">
                        <h5>Who can use YouEvolve?</h5>
                    </li>
                    <ul><li><h6>YouEvolve is open to anyone looking to improve their mental and emotional well-being. Whether you're seeking personal growth, self-care tools, or support through mental health challenges, our platform is inclusive and accessible for individuals of all ages and backgrounds.</h6></li></ul>
                    <br />
                    <li className="mt-3">
                    <h5>Is YouEvolve available worldwide? </h5>
                    </li>
                   <ul><li><h6>Yes, YouEvolve is accessible to users globally, wherever there's internet connectivity. Our platform aims to provide mental health support and resources for diverse populations and cultures.</h6></li></ul>
                    <br />
                    
                </ol>
                <br />
                {/* Features */}
                <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Features</h2></div>
                <br />
                <ol>
                    <li>
                        <h5>What kind of tools does YouEvolve offer?</h5>
                    </li>
                    
                    <ul><li>
                        <h6>YouEvolve offers a variety of art-based self-help tools, including:</h6>
                        <ul>
                            <li><h6>Guided art therapy sessions</h6></li>
                            <li><h6>Journaling prompts for emotional reflection</h6></li>
                            <li><h6>Meditation and mindfulness practices</h6></li>
                            <li><h6>Interactive visual aids to track emotions and moods</h6></li>
                            <li><h6>Creative exercises to help users express their feelings</h6></li>
                        </ul>
                    </li></ul>
                    <br />

                    <li><h5> Is there a feature for professionals on YouEvolve? </h5></li>
                    <ul><li><h6>Yes, YouEvolve also includes features for therapists and counselors. Professionals can join the platform to offer services, host group therapy sessions, and manage their clients efficiently. Counselors have access to analytics and reporting tools to help them track their clients’ progress.</h6></li></ul>
                    <br />

                    <li><h5>Can I access personalized therapy or counseling sessions?</h5></li>
                    <ul><li><h6>Yes, YouEvolve connects users with certified therapists and counselors for personalized, one-on-one sessions. You can book appointments through the platform and select a professional who suits your needs.</h6></li></ul>
                    <br />
                </ol>
                <br />

                {/* Account and Privacy */}
                <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Account and Privacy</h2></div>
                <ol>
                    <li><h5>How do I create an account on YouEvolve?</h5></li>
                    <ul><li><h6>Creating an account is simple. Just download the YouEvolve app or visit our website, select “Sign Up,” and follow the prompts to provide your information. You’ll be able to set preferences, access tools, and begin your journey toward better mental health.</h6></li></ul>
                    <br />

                    <li><h5> Is my data secure on YouEvolve?</h5></li>
                    <ul><li><h6>Absolutely. We take privacy and data security seriously. Your personal information is protected using industry-standard encryption and follows global privacy regulations. We do not share your information with third parties without your consent.</h6></li></ul>
                    <br />

                    <li><h5>Can I delete my account?</h5></li>
                    <ul><li><h6>Yes, you can delete your account at any time. Simply visit your account settings, select "Delete Account," and follow the prompts. All your data will be permanently removed from our system upon deletion.</h6></li></ul>
                    <br />       

                </ol>
                <br />

                {/* Accessibility and Inclusion */}
                <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Accessibility and Inclusion</h2></div>
                <ol>
                    <li><h5> Is YouEvolve accessible to people with disabilities?</h5></li>
                    <ul><li><h6>Yes, we are committed to accessibility. Our platform is designed to be inclusive, offering features like screen reader compatibility, adjustable text sizes, and other accessibility tools to ensure everyone can benefit from our resources.</h6></li></ul>
                    <br />

                    <li><h5>Is the platform available in multiple languages?</h5></li>
                    <ul><li><h6>Currently, YouEvolve offers support in several languages, and we continue to expand our language options. You can set your preferred language in your account settings.</h6></li></ul>
                    <br />

                    <li><h5>How does YouEvolve ensure cultural inclusivity?</h5></li>
                    <ul><li><h6>We prioritize cultural sensitivity in all our content, therapy services, and community features. Our platform includes content that respects and honors diverse cultural backgrounds, ensuring that all users feel seen, heard, and supported.</h6></li></ul>
                    <br />
                </ol>
                <br />

                {/* Subscription and Pricing */}
                <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Subscription and Pricing</h2></div>
                <ol>
                    <li><h5> How much does YouEvolve cost? </h5></li>
                    <ul><li><h6>YouEvolve offers various subscription tiers, including a free version with basic tools and resources. Our premium subscriptions provide access to additional features such as one-on-one counseling, exclusive content, and advanced tracking tools. You can choose a plan that fits your needs and budget.</h6></li></ul>
                    <br />

                    <li><h5>Can I try the platform before subscribing?</h5></li>
                    <ul><li><h6>Yes, YouEvolve offers a free trial period for premium features, allowing you to explore the platform and its tools before committing to a subscription.</h6></li></ul>
                    <br />

                    <li><h5>Are there discounts for students or low-income individuals?</h5></li>
                    <ul><li><h6>Yes, we offer discounted rates for students, low-income users, and those facing financial hardship. Contact our support team to inquire about eligibility and available discounts.</h6></li></ul>
                    <br />

                </ol>
                <br />

                 {/* Support and Community */}
                 <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Support and Community</h2></div>
                <ol>
                    <li><h5>How can I contact customer support?</h5></li>
                    <ul><li><h6> If you have any questions or need assistance, you can reach our support team by emailing  <span><a href="mailto:support@youevolve.in">support@youevolve.in</a></span> or using the "Help" section within the app to chat with a representative.</h6></li></ul>
                    <br />

                    <li><h5>Are there community groups or forums on YouEvolve?</h5></li>
                    <ul><li><h6>Yes, YouEvolve provides a safe and supportive space for users to engage with one another through community forums and group discussions. These spaces foster connections and allow users to share experiences, offer encouragement, and learn from each other.</h6></li></ul>
                    <br />

                    <li><h5>How do I find a therapist or counselor on YouEvolve?</h5></li>
                    <ul><li><h6>You can browse our list of verified therapists and counselors through the "Find a Therapist" section of the app. You can filter by specialty, availability, and preferred communication method (video, chat, phone, etc.) to find the best fit for you.</h6></li></ul>
                    <br />
                </ol>
                <br />


                 {/* Platform Policies */}
                 <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Platform Policies</h2></div>
                <ol>
                    <li><h5>Does YouEvolve provide emergency mental health support?</h5></li>
                    <ul><li><h6>YouEvolve is not a crisis intervention service. If you are in immediate need of help, we encourage you to contact a local emergency service or a crisis hotline. We can, however, provide resources and direct you to appropriate services.</h6></li></ul>
                    <br />

                    <li><h5>Can I use YouEvolve if I'm under 18? </h5></li>
                    <ul><li><h6>Yes, minors can use YouEvolve with parental consent. We offer resources tailored for young users and provide options for parents to monitor their children’s use of the platform.</h6></li></ul>
                    <br />

                    <li><h5>How does YouEvolve handle harassment or inappropriate content?</h5></li>
                    <ul><li><h6>We have a strict policy against harassment, bullying, and inappropriate content. If you experience or witness such behavior, please report it to our moderation team. We will investigate and take necessary action, including account suspension or removal from the platform.</h6></li></ul>
                    <br />
                </ol>
                <br />

                 {/* Miscellaneous */}
                 <div className="d-flex justify-content-center text-uppercase text-decoration-underline"><h2>Miscellaneous</h2></div>
                <ol>
                    <li><h5>Can I share my progress with others?</h5></li>
                    <ul><li><h6>Yes, YouEvolve allows you to track your mental health journey and share your progress with trusted friends, family members, or your therapist. You can choose to share specific achievements, journals, or reflections in a private or public setting.</h6></li></ul>
                    <br />

                    <li><h5>Does YouEvolve offer resources for organizations?</h5></li>
                    <ul><li><h6>es, YouEvolve provides enterprise solutions for companies, schools, and other organizations seeking to support the mental well-being of their employees or members. Reach out to our team for more information on corporate or group plans.</h6></li></ul>
                    <br />

                    <li><h5> How can I provide feedback about YouEvolve?</h5></li>
                    <ul><li><h6>We value your feedback! You can send suggestions or report issues directly through the app’s "Feedback" section or email us at <span><a href="mailto:feedback@youevolve.in">feedback@youevolve.in</a></span>.</h6></li></ul>
                    <br />

                </ol>
                <br />

                <div><h6>We hope these FAQs help you understand YouEvolve better! If you have any further questions, feel free to reach out to our team anytime.</h6></div>
                <br />

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About